.CentreHealth {

  @media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1190px;
    }
  }
  @media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1340px;
    }
  }

  h1 {
    color: #005B92;
    font-size: 24px;
  }

  h2 {
    color: #005B92;
    font-size: 20px;
  }

  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #2E9FE2;
    margin-bottom: 0;
  }

  p {
    font-family: Arial, Helvetica, sans-serif;
  }

  .btn {
    border-radius: 0;
  }

  // .relatedTopic {
  //     display: flex;
  //     flex-flow: column;
  //     width: 100%
  // }

  .btn {
    background-color: #038cd0;
    border-color: #038cd0;
    color: white;
    &.btn-link {
      color: #007bff;
      background-color: transparent;
      padding: 0;
      border: none;
    }
  }

}