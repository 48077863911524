.CentreHealth {
  .Advertisement {
    border: 1px solid;
    border-color: #d7d7d7;
    text-align: right;
    .ad-text {
      color: #555555;
      font-size: 24px;
      font-family: Arial, Helvetica, sans-serif;

    }
    img {
      max-width: 100%;
      margin-bottom: 20px;
    }
    .logo {
      margin-bottom: 20px;
      img {
        width: 35px;
        margin: 0;
      }
    }
    > div {
      margin-bottom: 20px;
      margin-right: 20px;
    }
  }
}