.CentreHealth {
  .TopicContent {
    line-height: normal!important;
    .wkTopic {
      font-size: 14px;


      h1 {
        font-family: 'Arial-BoldMT', 'Arial Bold', 'Arial', sans-serif;
        font-weight: 700;
        margin:0;
        padding:0;

        &.wkTopicTitle {
          font-size: 18px;
          margin-bottom: 20px;
        }
        &.wkQuestion {
          font-size: 16px;
          color: #555555;
        }
      }
      p.wkAnswer {
      }

    }

    .wkTopicAttribution {
      border-top: 1px solid #ccc;
      padding-top: 10px;
      font-size: .9em;

      .wkTopicDisclaimer {
        margin-top: 10px;
        font-size: .9em;
      }
    }

  }
}