.CentreHealth {
  .QuestionAnswerForm {
    margin-bottom: 25px;

    form {
      background-color: #f2f2f2;
      border: 1px solid #d7d7d7;
      border-radius: 5px;

      .input-group {
        margin: auto;
        margin-top: 19px;
        margin-bottom: 19px;
        max-width: 80%;

        input {
          border-color: #d7d7d7;
          border-radius: 25px 0px 0px 25px;
          height: 50px;
          padding-left: 25px;
          &:focus {
            box-shadow: none;
            border-color: #d7d7d7;
          }
          &:-internal-autofill-selected {
            background-color: white!important;
          }

        }
        .btn {
          border-radius: 0px 25px 25px 0px;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }

    // .border-settings {
    //     border-radius: 25px;
    //     border: 1px solid gray;
    // }

    // .button-settings {
    //     border-radius: 25px;
    // }
  }
}