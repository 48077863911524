.CentreHealth {
  .AutoSuggestInput {
    display: flex; flex: 1 1 0%; height: 100%; position: relative;

    &.has-aux {
      .rbt-aux {
        align-items: center;
        display: flex;
        bottom: 0;
        justify-content: center;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 34px;
        color: gray;
      }
    }

    .dropdown-item {
      line-height: 1.2rem;
      i {
        color: grey;
        font-size: smaller;
      }

      &.active i {
        color: #a4c0dc;
      }
    }
  }

}