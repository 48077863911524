.CentreHealth {
  .Feedback {
    margin-bottom: 10px;
    .btn, .btn.focus, .btn:active {
      line-height: 0;
      padding: 5px 7px 5px 7px;
      background-color: white;
      color: #7F7F7F;
      border-color: #d7d7d7;
      box-shadow: none;
    }

    .btn:hover {
        color: #005b92;
        background-color: #e7f2f9;
    }

    .btn.disabled {
      color: #d7d7d7;
      opacity: 1;
      &:hover {
        background-color: white;
      }
    }

    .btn.active {
      color: green;
    }

    .Feedback-Positive {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .Feedback-Negative {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      svg path {
        transform: scale(-1, 1) translate(-100%);
      }
    }
  }
}