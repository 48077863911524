$HeaderFontColor: #038CD0F2;


.CentreHealth {

  header {
    background-color: #D9EFFC;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 40px;



    .banner {
      padding-top: 1rem;
      padding-bottom: 1rem;
      color: $HeaderFontColor;
    }

    .logo {
      vertical-align: middle!important;
      text-align: center;
      .logo-text {
        text-align: left;
        > span {
          font-size: 15px;
          font-weight: 200;
          font-style: italic;
        }
      }
    }
    // Large screens (aka xl on grid)
    @media (min-width: 1200px) {
      .logo {
          text-align: left;
      }
      .nav {
        -ms-flex-pack: end!important;
        justify-content: flex-end!important;
      }
    }

    .image-set {
      display: inline-block;
      width: 70px;
    }

    .nav-item {
      font-size: 16px;
      a {
        color: $HeaderFontColor;
      }
    }

    .backgroundImageDiv {
      // position: absolute;
      width: 100%;
      // height: 350px;
      background-color: rgba(255,255,255,0);
      background-position: center center;
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-size: cover;
      background-origin: border-box;
      border: none;
      border-radius: 0px;
    }

    .imagecolorDiv {
      // margin-top: 25px;
      background-color: $HeaderFontColor;
      padding-left: 35px;
      padding-right: 35px;
      padding-top: 1rem;
      line-height: normal;
    }
  }
}

