.CentreHealth {
  .AgeFilter {


    .btn {
      border-radius: 5px!important;
      margin-right: 15px;
      font-size: 11px;
      text-transform: uppercase;
      background-color: white;
      color: #038CD0;
      border: none;

      &:focus {
        box-shadow: none!important;
      }

      &.active, &.active:hover {
        color: white;
        background-color: #005b92;
      }

      &:hover {
        background-color: #cce4f3;
      }

    }
  }
}