.CentreHealth {
  .QuestionAnswer {
    border-left: 0px;
    border-right: 0px;

    .cardHeaderSetting {
        background-color: white;
    }
    .cardHeaderWithOpenAccordion {
      border-bottom: 0px;
      background-color: white;
    }

    a.learn-more {
      color: #038cd0;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      font-size: 14px;
    }

    .Feedback {
      margin-top: 15px;
    }

  }

}