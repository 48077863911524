.wk-chatbot-button {
  z-index: 99999;
  position: fixed;
  bottom: 15px;
  right: 15px;
  border-radius: 22px !important;
  font-weight: 600;
  font-size:16px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.wk-chatbot-container {
    z-index: 99999;
    position: fixed;
    bottom: 0;
    right: 15px;
    width: 450px;
    padding: 0px;
  }
  .header {
    background-color: #038cd0;
  }
  .card-body {
    padding: 0px;
  }
  .iframe-body {
    width: 450px;
    border: 0;
    margin: 0;
    height: 600px;
  }
@media (max-height: 800px) {
  .iframe-body {
    height: 725px;
  }
}
@media (max-height: 700px) {
  .iframe-body {
    height: 625px;
  }
} 
